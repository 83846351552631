import request from '@/utils/request';

const appName = "huanxinxiang-yunxian";
// 单笔支付
export const alipayNoSignSMSPay = (data, params) => request({
  url: '/huanxinxiang-service/v1/alipay-no-sign-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
  params,
});



// 连续签约-需要验证码
export const alipaySMSPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 获取短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});
